import classNames from "classnames";
import { Entry } from "contentful";

import Container from "~/components/common/container";
import EscapeNewLine from "~/components/common/escape-new-line";
import Grid from "~/components/common/grid";
import HighlightedText from "~/components/common/highlighted-text";
import RichText from "~/components/common/rich-text";
import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType } from "~/utils/editorial-utils";

import Countdown from "../../common/countdown";
import Mosaic from "../../common/mosaic/mosaic";
import EditorialLink from "../editorial-link/editorial-link";
import styles from "./editorial-banner.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function EditorialBanner(props: Props) {
  assertEditorialType<Editorials.EditorialBanner>(props.entry, "editorialBanner");
  const breakpoint = useBreakpoint();

  const {
    anchorId,
    pretitle,
    title,
    subtitle,
    paragraph,
    description,
    descriptionBelowAsset,
    countdown,
    link,
    linkIsBlackVariant,
    flipTextOnMobile,
    mirrorTextOnDesktop,
    isBigTitle,
    mosaicVariant,
    hasBackground,
  } = props.entry.fields;

  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  return (
    <div
      id={anchorId}
      className={classNames(
        styles.container,
        hasBackground ? styles.background : undefined,
        flipTextOnMobile ? styles.backgroundWithFlipTextOnMobile : undefined,
        mirrorTextOnDesktop ? styles.backgroundWithMirrorTextOnDesktop : undefined
      )}
    >
      <Container className={styles.wrapper}>
        <Grid className={styles.grid}>
          {pretitle && breakpoint === "mobile" ? (
            <p
              {...inspectorMode?.getProps("pretitle")}
              className={classNames(styles.pretitleMobile, isBigTitle ? styles.bigTitle : undefined)}
            >
              <EscapeNewLine text={pretitle} />
            </p>
          ) : null}
          <div
            className={classNames(
              styles.col1,
              mirrorTextOnDesktop ? styles.mirrorTextOnDesktop : null,
              mosaicVariant === "mosaic_7" ? styles.col1Mosaic7 : null
            )}
          >
            {pretitle && breakpoint === "desktop" ? (
              <p
                {...inspectorMode?.getProps("pretitle")}
                className={classNames(styles.pretitleDesktop, isBigTitle ? styles.bigTitle : undefined)}
              >
                <EscapeNewLine text={pretitle} />
              </p>
            ) : null}
            {title ? (
              <>
                {contentfulUtils.isHighlightText(title) ? (
                  <h2
                    {...inspectorMode?.getProps("title")}
                    className={classNames(styles.titleHighlighted, isBigTitle ? styles.bigTitle : undefined)}
                  >
                    <HighlightedText text={title} />
                  </h2>
                ) : (
                  <h2
                    {...inspectorMode?.getProps("title")}
                    className={classNames(styles.title, isBigTitle ? styles.bigTitle : undefined)}
                  >
                    <EscapeNewLine text={title} />
                  </h2>
                )}
              </>
            ) : null}
            {subtitle ? (
              <p {...inspectorMode?.getProps("subtitle")} className={styles.subtitle}>
                <EscapeNewLine text={subtitle} />
              </p>
            ) : null}
            {paragraph ? (
              <p {...inspectorMode?.getProps("paragraph")} className={styles.paragraph}>
                <EscapeNewLine text={paragraph} />
              </p>
            ) : null}
            {description ? (
              <div {...inspectorMode?.getProps("description")} className={styles.description}>
                <RichText text={description} />
              </div>
            ) : null}
            {countdown ? (
              <Countdown
                entry={props.entry}
                date={countdown}
                customStyles={{
                  containerCountdown: styles.containerCountdown,
                  wrapperCountdown: styles.wrapperCountdown,
                  labelCountdown: styles.labelCountdown,
                  numberCountdown: styles.numberCountdown,
                }}
              />
            ) : null}
            {link ? (
              linkIsBlackVariant ? (
                <EditorialLink entry={link} lookAsButton="primary" className={styles.link} />
              ) : (
                <EditorialLink entry={link} lookAsButton="secondary" className={styles.link} />
              )
            ) : null}
            {breakpoint === "mobile" ? (
              <>
                <Mosaic entry={props.entry} isTextAbove />
                {descriptionBelowAsset ? (
                  <div {...inspectorMode?.getProps("descriptionBelowAsset")} className={styles.descriptionBelowAsset}>
                    <RichText text={descriptionBelowAsset} />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          {breakpoint === "desktop" ? (
            <div
              className={classNames(
                styles.col2,
                mirrorTextOnDesktop ? styles.mirrorTextOnDesktop : null,
                mosaicVariant === "mosaic_7" ? styles.col2Mosaic7 : null,
                mosaicVariant === "mosaic_9" ? styles.col2Mosaic9 : null
              )}
            >
              <Mosaic entry={props.entry} isTextAbove />
              {descriptionBelowAsset ? (
                <div {...inspectorMode?.getProps("descriptionBelowAsset")} className={styles.descriptionBelowAsset}>
                  <RichText text={descriptionBelowAsset} />
                </div>
              ) : null}
            </div>
          ) : null}
        </Grid>
      </Container>
    </div>
  );
}
